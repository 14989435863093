import React, { useEffect } from "react";
import {Link} from "react-router-dom";

import Typed from "typed.js";

const Home = () => {

    // typed js ---------------------------------------
    useEffect(() => {
        new Typed(".typed", {
            strings: ["frindi", "Web designer", "freelancer", "web developer"],
            typeSpeed: 70,
            backSpeed: 20,
            loop: true,
            backDelay: 1000,
            fadeOut: false,
            fadeOutDelay: 500,
            showCursor: true,
            cursorChar: "",
            shuffle: false,
        });
    }, []);
    // ------------------x-------------x----------------

    return (
        <section className="home animate-top link">
            <div className="row">
                <div className="item col-md-6">
                    <div className="item-content">
                        <span className="top">hi there!</span>
                        <h3 className="im">
                            I'M <span className="typed"></span>
                        </h3>
                        <p className="desc">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit Modi hic libero inventore ipsum quos tempore ab
                            autem accusantium sit quibusdam.
                        </p>
                        <div className="socials">
                            <ul>
                                <li>
                                    <Link to="/">
                                        <i
                                            className="iconify"
                                            data-icon="bx:bxl-facebook"
                                            data-inline="false"
                                        ></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i
                                            className="iconify"
                                            data-icon="akar-icons:twitter-fill"
                                            data-inline="false"
                                        ></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i
                                            className="iconify"
                                            data-icon="uil:instagram"
                                            data-inline="false"
                                        ></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <i
                                            className="iconify"
                                            data-icon="akar-icons:github-fill"
                                            data-inline="false"
                                        ></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="img col-md-6">
                    <img src="assets/img/man-2.png" alt="me" />
                </div>
            </div>
        </section>
    );
};

export default Home;

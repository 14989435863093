import React, { useRef } from "react";
import {sendForm} from "@emailjs/browser";
import toastr from "toastr";

const Contact = () => {

    const form = useRef();

    const handelEmailSubmit = async (e) => {

        e.preventDefault();
        
        try {

            let sendEmail = await sendForm(
                    process.env.REACT_APP_EMAIL_SERVICE_ID, // server id
                    process.env.REACT_APP_EMAIL_TEMPLATE_ID, // template id
                    form.current,
                    process.env.REACT_APP_EMAIL_PUBLIC_KEY // public key
            )

            console.log(sendEmail.text);
            toastr.success(`The message has been sent successfully !!`);

        } catch (error) {
            toastr.error(error?.text);
        }

    }


    return (
        <section className="contact pd animate-top link">
            <div className="title">
                <h2>contact</h2>
            </div>
            {/* contact content */}
            <div className="contact-content pd-tb">
                <div className="row">
                    {/* <!-- contact item left --> */}
                    <div className="item left col-lg-6">
                        {/* <h5 className="title">contact info</h5>
                        <p className="desc">Always available for freelance work if the right project comes along, Feel free to contact me!</p> */}
                        {/* <!-- info --> */}
                        <div className="info">
                            {/* <!-- info item --> */}
                            <div className="info-item">
                                <div className="top">
                                    <i
                                        className="iconify"
                                        data-icon="carbon:location"
                                        data-inline="false"
                                    ></i>
                                    <h6 className="info-title">
                                        Visit My Studio
                                    </h6>
                                </div>
                                <div className="area">
                                    <span>Warnwe Park Streetperrine,</span>
                                    <span>FL 33157 New York City</span>
                                </div>
                            </div>
                            {/* <!-- info item --> */}
                            <div className="info-item">
                                <div className="top">
                                    <i
                                        className="iconify"
                                        data-icon="fluent:mail-48-regular"
                                        data-inline="false"
                                    ></i>
                                    <h6 className="info-title">mail</h6>
                                </div>
                                <div className="area">
                                    <span>info@domainname.com</span>
                                    <span>example@email.com</span>
                                </div>
                            </div>
                            {/* <!-- info item --> */}
                            <div className="info-item">
                                <div className="top">
                                    <i
                                        className="iconify"
                                        data-icon="carbon:phone-voice"
                                        data-inline="false"
                                    ></i>
                                    <h6 className="info-title">phones</h6>
                                </div>
                                <div className="area">
                                    <span>Phone: +01 245 756 6194</span>
                                    <span>Fax: +04 220 755 7188</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- contact item right --> */}
                    <div className="item right col-lg-6">
                        <div className="item-content">
                            <form ref={form} onSubmit={handelEmailSubmit}>
                                <div className="row">
                                    <div className="input remove-pd col-md-6">
                                        <input
                                            type="text"
                                            name="user_name"
                                            placeholder="Your Name"
                                            required
                                        />
                                    </div>
                                    <div className="input col-md-6">
                                        <input
                                            type="email"
                                            name="user_email"
                                            placeholder="Email"
                                            required
                                        />
                                    </div>
                                    <div className="input col-md-12">
                                        <input
                                            type="text"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                        />
                                    </div>
                                    <div className="input col-md-12">
                                        <textarea
                                            cols="30"
                                            rows="10"
                                            name="message"
                                            placeholder="Your Message"
                                            required
                                        ></textarea>
                                    </div>
                                    <div className="input col-md-12">
                                        <input
                                            type="submit"
                                            value="Contact Us"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;

import React, { useEffect } from "react";

const About = () => {

    // change range value ------------------------------
    useEffect(() => {
        let skills = document.querySelectorAll(".skills .item");

        skills.forEach((skil) => {
            let number = skil.querySelector(".number");
            number.style.left = number.innerHTML;
        });
    }, []);
    // ------------------x-------------x----------------

    return (
        <section className="about-cont pd animate-left link">
            <div className="about">
                <div className="title">
                    <h2>about me</h2>
                </div>
                <div className="about-info">
                    <div className="content">
                        <div className="row">
                            <div className="img col-md-6">
                                <div className="img-content">
                                    <img
                                        src="assets/img/about-img.png"
                                        alt="about-img"
                                    />
                                </div>
                            </div>
                            <div className="info col-md-6">
                                <div className="top">
                                    <h4>
                                        <span>Hi</span> There
                                    </h4>
                                </div>
                                <div className="desc">
                                    <p>
                                        I'm a Freelancer Front-end Developer
                                        with over 3 years of experience. I'm
                                        from a morroco. I code and create web
                                        elements for amazing people around the
                                        world. I like work with new people. New
                                        people new Experiences.
                                    </p>
                                    <p>
                                        I'm a Freelancer Front-end Developer
                                        with over 3 years of experience. I'm
                                        from a morroco.
                                    </p>
                                </div>
                                <div className="bottom">
                                    <div className="row">
                                        <div className="item col-sm-6">
                                            <div className="item-content">
                                                <span className="title">
                                                    Name:
                                                </span>
                                                <span>Mohamed Frindi</span>
                                            </div>
                                        </div>
                                        <div className="item col-sm-6">
                                            <div className="item-content">
                                                <span className="title">
                                                    Email:
                                                </span>
                                                <span>example@gmail.com</span>
                                            </div>
                                        </div>
                                        <div className="item col-sm-6">
                                            <div className="item-content">
                                                <span className="title">
                                                    Phone:
                                                </span>
                                                <span>+2126001145</span>
                                            </div>
                                        </div>
                                        <div className="item col-sm-6">
                                            <div className="item-content">
                                                <span className="title">
                                                    Skype:
                                                </span>
                                                <span>Your-Name</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fun-fact">
                <div className="fun-fact-content pd-tb">
                    <div className="row">
                        <div className="item col-md-6 col-lg-6 col-xl-3">
                            <div className="item-content">
                                <div className="icon">
                                    <span
                                        className="iconify"
                                        data-icon="clarity:briefcase-line"
                                        data-inline="false"
                                    ></span>
                                </div>
                                <div className="info">
                                    <span className="number">+100</span>
                                    <h4 className="title">projects</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-3">
                            <div className="item-content">
                                <div className="icon">
                                    <span
                                        className="iconify"
                                        data-icon="clarity:clock-line"
                                        data-inline="false"
                                    ></span>
                                </div>
                                <div className="info">
                                    <span className="number">+30</span>
                                    <h4 className="title">hours</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-3">
                            <div className="item-content">
                                <div className="icon">
                                    <span
                                        className="iconify"
                                        data-icon="clarity:home-line"
                                        data-inline="false"
                                    ></span>
                                </div>
                                <div className="info">
                                    <span className="number">+10</span>
                                    <h4 className="title">offices</h4>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-3">
                            <div className="item-content">
                                <div className="icon">
                                    <span
                                        className="iconify"
                                        data-icon="carbon:user-multiple"
                                        data-inline="false"
                                    ></span>
                                </div>
                                <div className="info">
                                    <span className="number">+300</span>
                                    <h4 className="title">clients</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="service">
                <div className="title">
                    <h2>service</h2>
                </div>
                <div className="service-content pd-tb">
                    <div className="row">
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="cil:brush"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">UI/UX Design</h5>
                                    <p className="desc">
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Error, quibusdam!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="fluent:phone-laptop-32-regular"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">responsive</h5>
                                    <p className="desc">
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Error, quibusdam!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="fluent:code-16-regular"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">development web</h5>
                                    <p className="desc">
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Error, quibusdam!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="codicon:settings"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">advanced options</h5>
                                    <p className="desc">
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Error, quibusdam!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="la:pencil-ruler"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">Unique design</h5>
                                    <p className="desc">
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Error, quibusdam!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="item col-md-6 col-lg-6 col-xl-4">
                            <div className="item-content">
                                <div className="icon">
                                    <div className="icon-content">
                                        <span
                                            className="iconify"
                                            data-icon="bi:phone"
                                            data-inline="false"
                                        ></span>
                                    </div>
                                </div>
                                <div className="info">
                                    <h5 className="title">mobile apps</h5>
                                    <p className="desc">
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Error, quibusdam!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="experience-skills">
                <div className="title">
                    <h2>experience</h2>
                </div>
                <div className="experience-skills-content row">
                    <div className="experience col-md-6">
                        <div className="experience-content">
                            <div className="experience-bg">
                                <div className="experience-body">
                                    <div className="item">
                                        <span className="top">2021 - 2018</span>
                                        <h5 className="title">Web Developer</h5>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet quo ei
                                            simul congue exerci ad nec admodum
                                            perfecto.
                                        </p>
                                    </div>
                                    <div className="item study">
                                        <span className="top">2017 - 2013</span>
                                        <h5 className="title">Web Designer</h5>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet quo ei
                                            simul congue exerci ad nec admodum
                                            perfecto.
                                        </p>
                                    </div>
                                    <div className="item">
                                        <span className="top">2012 - 2011</span>
                                        <h5 className="title">
                                            UI/UX Designer
                                        </h5>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet quo ei
                                            simul congue exerci ad nec admodum
                                            perfecto.
                                        </p>
                                    </div>
                                    <div className="item study">
                                        <span className="top">2010 - 2009</span>
                                        <h5 className="title">offpt morroco</h5>
                                        <p className="desc">
                                            Lorem ipsum dolor sit amet quo ei
                                            simul congue exerci ad nec admodum
                                            perfecto.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="skills col-md-6">
                        <div className="skills-content pd-tb">
                            <div className="item">
                                <span className="title">UX/UI designer</span>
                                <span className="number">50%</span>
                                <span className="range">
                                    <span
                                        className="range-body"
                                        style={{ width: "50%" }}
                                    ></span>
                                </span>
                            </div>
                            <div className="item">
                                <span className="title">web designer</span>
                                <span className="number">80%</span>
                                <span className="range">
                                    <span
                                        className="range-body"
                                        style={{ width: "80%" }}
                                    ></span>
                                </span>
                            </div>
                            <div className="item">
                                <span className="title">development</span>
                                <span className="number">90%</span>
                                <span className="range">
                                    <span
                                        className="range-body"
                                        style={{ width: "90%" }}
                                    ></span>
                                </span>
                            </div>
                            <div className="item">
                                <span className="title">graphic design</span>
                                <span className="number">30%</span>
                                <span className="range">
                                    <span
                                        className="range-body"
                                        style={{ width: "30%" }}
                                    ></span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
